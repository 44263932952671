import React, { useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Title from "../../UI/Title";
import { ProjectDetailsContext } from "./ProjectDetailsContext";
import moment from "moment";
import Subtitle from "../../UI/Subtitle";
import Cabinet from "../../Cabinet/Cabinet";
import Accessories from "./Accessories";
import Cuts from "./Cuts";
import DropDownExport from "../../UI/DropDownExport";
import { ProjectsContext } from "../ProjectsContext";
import { useTranslation } from "react-i18next";

const ProjectDetails = ({ history }) => {
  const { id } = useParams();

  const {
    projectData,
    updateProject,
    loadProject,
    currentProject,
    setCurrentProject,
    isEditing,
    setIsEditing,
    inputCabinetWidth,
    inputCabinetHeight,
    inputCabinetDepth,
    modules,
    setModules,
  } = useContext(ProjectDetailsContext);

  const { inputProjectTotal, getClientName } = useContext(ProjectsContext);

  useEffect(() => {
    setCurrentProject(id);

    return () => {
      setModules([]);
    };
  }, [id]);

  const handleSave = () => {
    const updDetails = {
      cabinet: {
        width: inputCabinetWidth,
        height: inputCabinetHeight,
        depth: inputCabinetDepth,
      },
      modules: modules,
      total: inputProjectTotal,
    };

    updateProject(currentProject, updDetails);
    setIsEditing(false);
  };

  const handleCancel = () => {
    // setCurrentProject();
    // setModules([]);
    loadProject(currentProject);
    setIsEditing(false);
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    setCurrentProject();
    navigate(-1);
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="p-2 pl-0 mb-6 cursor-pointer" onClick={handleGoBack}>
            <svg
              className="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </div>
          <Title appendClass="capitalize" value={projectData.name} />
        </div>

        <div hidden={!isEditing}>
          <button
            onClick={handleCancel}
            className="h-10 mr-2 px-4 rounded-md font-medium text-white bg-gray-400 hover:bg-gray-300"
          >
            {t("common.cancel")}
          </button>

          <button
            onClick={handleSave}
            className="h-10 px-4 rounded-md font-medium text-white bg-green-400 hover:bg-green-300"
          >
            {t("common.save")}
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 mb-10">
        <div className="bg-white px-6 py-4 rounded-lg shadow-sm">
          <h2 className="mb-1 text-xs font-bold uppercase text-gray-400">
            {t("project.client")}
          </h2>
          <p>{getClientName(projectData.clientId)}</p>
        </div>

        <div className="bg-white px-6 py-4 rounded-lg shadow-sm">
          <h2 className="mb-1 text-xs font-bold uppercase text-gray-400">
            {t("project.status")}
          </h2>
          <p>{t("project." + projectData.status)}</p>
        </div>

        <div className="bg-white px-6 py-4 rounded-lg shadow-sm">
          <h2 className="mb-1 text-xs font-bold uppercase text-gray-400">
            {t("project.creation_date")}
          </h2>
          <p>
            {moment(
              projectData.createdAt && projectData.createdAt.toDate()
            ).format("YYYY-MM-DD HH:mm")}
          </p>
        </div>
      </div>

      <Subtitle value={t("project.cabinet_designer")} />
      <div className="bg-white mb-10 px-6 py-4 rounded-lg shadow-sm">
        <Cabinet />
      </div>

      <Subtitle value={t("project.list_accessories")} />

      <Accessories />

      <div className="flex justify-between">
        <Subtitle value={t("project.list_cuts")} />
        <DropDownExport />
      </div>

      <Cuts />
    </>
  );
};

export default ProjectDetails;
