import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AuthProvider } from "./Auth";
import { SettingsProvider } from "./components/Settings/SettingsContext";
import { ProjectsProvider } from "./components/Projects/ProjectsContext";
import { ClientsProvider } from "./components/Clients/ClientsContext";
import { ProjectDetailsProvider } from "./components/Projects/Project/ProjectDetailsContext";

import Nav from "./components/Nav";
import Login from "./components/Login";
import Main from "./components/Main";
import ProjectDetails from "./components/Projects/Project/ProjectDetails";
import Clients from "./components/Clients/Clients";
import Settings from "./components/Settings/Settings";
import PrivateRoute from "./PrivateRoute";
import { CountProvider } from "./components/Cabinet/CountContext";

function App() {
  return (
    <AuthProvider>
      <SettingsProvider>
        <ClientsProvider>
          <ProjectsProvider>
            <ProjectDetailsProvider>
              <CountProvider>
                <div className="app flex flex-col min-h-screen">
                  <Router>
                    <Nav />
                    <main className="flex-1 px-20 py-10 bg-gray-100">
                      <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/" element={<PrivateRoute />}>
                          <Route path="/" element={<Main />} />
                        </Route>
                        <Route
                          path="/view-project/:id"
                          element={<PrivateRoute />}
                        >
                          <Route
                            path="/view-project/:id"
                            element={<ProjectDetails />}
                          />
                        </Route>
                        <Route path="/clients" element={<PrivateRoute />}>
                          <Route path="/clients" element={<Clients />} />
                        </Route>
                        <Route path="/settings" element={<PrivateRoute />}>
                          <Route path="/settings" element={<Settings />} />
                        </Route>
                      </Routes>
                    </main>
                  </Router>
                </div>
              </CountProvider>
            </ProjectDetailsProvider>
          </ProjectsProvider>
        </ClientsProvider>
      </SettingsProvider>
    </AuthProvider>
  );
}

export default App;
