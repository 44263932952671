import React, { useCallback, useContext } from "react";
import { Routes, Route, Navigate } from "react-router";
import { AuthContext } from "../Auth";
import config from "../firebase";
import Title from "./UI/Title";
import logo from "../images/veta-logo.svg";
import { useTranslation } from "react-i18next";

const Login = ({ history }) => {
  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await config
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        history.push("/");
      } catch (error) {
        console.error(error);
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  const { t } = useTranslation();

  if (currentUser) {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/" />} />
      </Routes>
    );
    // return <Component location="/"} />;
  }

  return (
    <div className="absolute flex items-center justify-center w-full h-full bg-gray-600">
      <div className="flex flex-col justify-between items-center w-1/3 -mt-20 shadow-sm">
        <img
          className="block h-16 mb-10 shadow-lg"
          src={logo}
          alt="Veta Logo"
        />

        <form
          className="p-10 bg-white shadow-lg rounded-md"
          onSubmit={handleLogin}
        >
          <Title value={t("login.welcome")} />
          <input
            className="w-full p-2 mb-5 border border-gray-300"
            name="email"
            type="email"
            placeholder={t("login.email_placeholder")}
          />
          <input
            className="w-full p-2 mb-5 border border-gray-300"
            name="password"
            type="password"
            placeholder={t("login.pw_placeholder")}
          />
          <button
            className="w-full h-10 bg-green-400 hover:bg-green-300"
            type="submit"
          >
            {t("common.login")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
