import React, { useContext, useState, useEffect } from "react";
import { ProjectDetailsContext } from "../Projects/Project/ProjectDetailsContext";
import ButtonRemove from "../UI/ButtonRemove";
import A1PB from "./Modules/A1PB";
import A2PB from "./Modules/A2PB";
import A2PP from "./Modules/A2PP";

const Module = ({ index, cod, width }) => {
  const { removeModule, editModule, setIsEditing } = useContext(
    ProjectDetailsContext
  );

  const [moduleWidth, setModuleWidth] = useState(width);

  useEffect(() => {
    setModuleWidth(width);
  }, [width]);

  const switchModule = () => {
    switch (cod) {
      case "A1PB":
        return <A1PB />;
      case "A2PB":
        return <A2PB />;
      case "A2PP":
        return <A2PP />;

      default:
        return;
    }
  };

  const updateModule = (e) => {
    setModuleWidth(parseInt(e.target.value));
    editModule(index, parseInt(e.target.value));
    setIsEditing(true);
  };

  return (
    <div
      style={{
        width: (moduleWidth / 10) * 2 + "px",
        height: "100%",
      }}
    >
      {switchModule()}
      <ButtonRemove
        click={() => {
          removeModule(index);
          setIsEditing(true);
        }}
      />
      <div className="module-controls flex justify-center items-center mt-3">
        <input
          className="absolute bg-green-400 text-xs text-white font-medium rounded-full z-10 pl-2"
          type="number"
          min="300"
          step="10"
          value={moduleWidth}
          onChange={(e) => updateModule(e)}
        />
        <div className="width-mark bg-green-400 rounded-full"></div>
      </div>
    </div>
  );
};

export default Module;
