export default (state, action) => {
  switch (action.type) {
    case "UPDATE_SETTINGS":
      return {
        ...state,
        settings: [...action.payload],
      };
    case "EDIT_SETTING":
      return {
        ...state,
        settings: [...state.settings],
      };
    default:
      return state;
  }
};
