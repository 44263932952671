import React from "react";
import "./A2PP.scss";

const A2PP = () => {
  return (
    <div className="module-A2PP">
      <div></div>
      <div></div>
    </div>
  );
};

export default A2PP;
