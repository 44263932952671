import React, { useContext } from "react";
import { SettingsContext } from "./SettingsContext";
import Modal from "../UI/Modal";
import { useTranslation } from "react-i18next";

const AccModal = () => {
  const {
    inputItem,
    setInputItem,
    inputQty,
    setInputQty,
    inputPrice,
    setInputPrice,
    currentAcc,
    setIsEditing,
    editPrice,
    showModal,
    setShowModal,
  } = useContext(SettingsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputItem === "" || inputQty === "" || inputPrice === "") return;

    const itemDetails = {
      item: inputItem,
      qty: parseInt(inputQty),
      price: parseFloat(inputPrice),
    };

    editPrice(currentAcc, itemDetails);
    setShowModal(false);
    handleClear();
  };

  const handleClear = () => {
    setInputItem("");
    setInputQty("");
    setInputPrice("");
  };

  const closeModal = () => {
    handleClear();
    setIsEditing(false);
    setShowModal(false);
  };

  const { t } = useTranslation();

  if (!showModal) {
    return null;
  }

  return (
    <Modal title="Edit Accessory">
      <form onSubmit={handleSubmit}>
        <input
          className="block px-1 h-10 mb-2 w-full rounded-sm border-gray-300 border"
          name="itemName"
          type="input"
          value={inputItem}
          onChange={(e) => setInputItem(e.target.value)}
          placeholder={t("project.item_name")}
          disabled
        />

        <input
          className="px-1 h-10 mb-2 w-full rounded-sm border-gray-300 border"
          name="itemQty"
          type="number"
          value={inputQty}
          onChange={(e) => setInputQty(e.target.value)}
          placeholder={t("project.item_qty")}
          disabled
        />

        <input
          className="px-1 h-10 mb-2 w-full rounded-sm border-gray-300 border"
          name="itemPrice"
          type="number"
          step=".01"
          value={inputPrice}
          onChange={(e) => setInputPrice(e.target.value)}
          placeholder={t("project.item_price")}
        />

        <div className="flex justify-end mt-5">
          <button
            className="px-5 py-3 inline-block text-md rounded-md text-white bg-gray-400 hover:bg-gray-300"
            type="button"
            onClick={(e) => {
              closeModal();
            }}
          >
            {t("common.cancel")}
          </button>
          <button
            className="px-5 py-3 ml-2 inline-block text-md rounded-md text-white bg-green-400 hover:bg-green-300"
            type="submit"
          >
            {t("common.save")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AccModal;
