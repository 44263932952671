export default (state, action) => {
  switch (action.type) {
    case "UPDATE_PROJECTS":
      return {
        ...state,
        projects: [...action.payload],
      };
    case "ADD_PROJECT":
      return {
        ...state,
        projects: [...state.projects, action.payload],
      };
    case "EDIT_PROJECT":
      return {
        ...state,
        projects: [...state.projects],
      };
    case "DELETE_PROJECT":
      return {
        ...state,
        projects: [...state.projects],
      };
    case "RESET_PROJECTS":
      return {
        projects: [],
      };
    default:
      return state;
  }
};
