import React, { useContext } from "react";
import { ProjectsContext } from "../ProjectsContext";
import Modal from "../../UI/Modal";
import { ClientsContext } from "../../Clients/ClientsContext";
import {
  DEFAULT_WIDTH,
  DEFAULT_HEIGHT,
  DEFAULT_DEPTH,
} from "../../Cabinet/variables";
import { useTranslation } from "react-i18next";

const ProjectModal = () => {
  const { clients } = useContext(ClientsContext);

  const {
    inputProjectName,
    setInputProjectName,
    inputProjectClientId,
    setInputProjectClientId,
    inputProjectStatus,
    setInputProjectStatus,
    inputProjectCreatedAt,
    setInputProjectCreatedAt,
    isEditing,
    setIsEditing,
    addProject,
    editProject,
    deleteProject,
    currentProject,
    showModal,
    setShowModal,
  } = useContext(ProjectsContext);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      inputProjectName === "" ||
      inputProjectClientId === "" ||
      inputProjectStatus === ""
    )
      return;

    const newProjectDetails = {
      name: inputProjectName,
      clientId: inputProjectClientId,
      status: inputProjectStatus,
      total: "0",
      createdAt: new Date(),
      cabinet: {
        width: DEFAULT_WIDTH,
        height: DEFAULT_HEIGHT,
        depth: DEFAULT_DEPTH,
      },
    };

    const editProjectDetails = {
      name: inputProjectName,
      clientId: inputProjectClientId,
      status: inputProjectStatus,
      createdAt: inputProjectCreatedAt,
    };

    isEditing
      ? editProject(currentProject.id, editProjectDetails)
      : addProject(newProjectDetails);
    setShowModal(false);
    handleClear();
  };

  const handleDelete = () => {
    deleteProject(currentProject.id);
    setIsEditing(false);
    handleClear();
    closeModal();
  };

  const handleClear = () => {
    setInputProjectName("");
    setInputProjectClientId("");
    setInputProjectStatus("");
    setInputProjectCreatedAt("");
  };

  const closeModal = () => {
    handleClear();
    setIsEditing(false);
    setShowModal(false);
  };

  const { t } = useTranslation();

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      title={isEditing ? t("project.edit_project") : t("project.new_project")}
    >
      <form onSubmit={handleSubmit}>
        <input
          className="block px-1 h-10 mb-2 w-full rounded-sm border-gray-300 border"
          name="projectName"
          type="input"
          value={inputProjectName}
          onChange={(e) => setInputProjectName(e.target.value)}
          placeholder={t("project.project_name")}
        />

        <select
          className={
            "block px-1 h-10 mb-2 w-full rounded-sm border-gray-300 border " +
            (inputProjectClientId === "" ? "text-gray-400" : "")
          }
          onChange={(e) => setInputProjectClientId(e.target.value)}
          value={inputProjectClientId}
        >
          <option className="opacity-50" value="">
            {t("project.select_client")}
          </option>
          {clients.map((c) => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))}
        </select>

        <select
          className={
            "block px-1 h-10 mb-2 w-full rounded-sm border-gray-300 border " +
            (inputProjectStatus === "" ? "text-gray-400" : "")
          }
          onChange={(e) => setInputProjectStatus(e.target.value)}
          value={inputProjectStatus}
        >
          <option className="opacity-50" value="0">
            {t("project.select_status")}
          </option>
          <option value="inprogress">{t("project.inprogress")}</option>
          <option value="accepted">{t("project.accepted")}</option>
          <option value="rejected">{t("project.rejected")}</option>
        </select>

        <div className="flex justify-end mt-5">
          {isEditing && (
            <button
              className="px-5 py-3 mr-auto inline-block text-md rounded-md text-white bg-red-400 hover:bg-red-300"
              type="button"
              onClick={() => {
                handleDelete();
              }}
            >
              {t("common.delete")}
            </button>
          )}
          <button
            className="px-5 py-3 inline-block text-md rounded-md text-white bg-gray-400 hover:bg-gray-300"
            type="button"
            onClick={(e) => {
              closeModal();
            }}
          >
            {t("common.cancel")}
          </button>
          <button
            className="px-5 py-3 ml-2 inline-block text-md rounded-md text-white bg-green-400 hover:bg-green-300"
            type="submit"
          >
            {isEditing ? t("common.save") : t("common.add")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ProjectModal;
