import React, { useContext } from "react";
import { NumericFormat } from "react-number-format";
import { ProjectDetailsContext } from "./ProjectDetailsContext";
// import Accessory from "./Accessory";
import moduleTypes from "../../../moduleTypes";
import { SettingsContext } from "../../Settings/SettingsContext";
import { CountContext } from "../../Cabinet/CountContext";
import { useTranslation } from "react-i18next";

const Accessories = () => {
  const { modules, inputCabinetHeight, inputCabinetDepth } = useContext(
    ProjectDetailsContext
  );
  const { settings } = useContext(SettingsContext);
  const { getTypeCount, modulesWidth } = useContext(CountContext);

  const { t } = useTranslation();

  if (settings.length === 0) return <></>;

  const bisagraQty =
    getTypeCount("A1PB") * moduleTypes.A1PB.bisagra +
    getTypeCount("A2PB") * moduleTypes.A2PB.bisagra +
    getTypeCount("A2PP") * moduleTypes.A2PP.bisagra;
  const bisagraPrice = settings[0].price;
  const bisagraLabel = settings[0].item;
  const bisagraTotal = bisagraQty > 0 ? bisagraQty * bisagraPrice : 0;

  const tiradorQty =
    getTypeCount("A1PB") * moduleTypes.A1PB.tirador +
    getTypeCount("A2PB") * moduleTypes.A2PB.tirador +
    getTypeCount("A2PP") * moduleTypes.A2PP.tirador;
  const tiradorPrice = settings[4].price;
  const tiradorLabel = settings[4].item;
  const tiradorTotal = tiradorQty > 0 ? tiradorQty * tiradorPrice : 0;

  const cantoQty =
    (modulesWidth * 2 +
      getTypeCount("A1PB") * moduleTypes.A1PB.cantoH * inputCabinetHeight +
      getTypeCount("A2PB") * moduleTypes.A2PB.cantoH * inputCabinetHeight +
      getTypeCount("A2PP") * moduleTypes.A2PP.cantoH * inputCabinetHeight) /
    1000;
  const cantoPrice = settings[1].price;
  const cantoLabel = settings[1].item;
  const cantoTotal = cantoQty > 0 ? cantoQty * cantoPrice : 0;

  const melaminicoQty =
    modules !== undefined
      ? (modulesWidth * inputCabinetHeight + // front
          2 * modulesWidth * inputCabinetDepth + // sides
          2 * inputCabinetHeight * inputCabinetDepth) / // top and bottom
        (2592 * 1822)
      : 0;
  const melaminicoPrice = settings[3].price;
  const melaminicoLabel = settings[3].item;
  const melaminicoTotal = melaminicoQty * melaminicoPrice;

  const fondoQty = (modulesWidth * inputCabinetHeight) / (2000 * 2000);
  const fondoPrice = settings[2].price;
  const fondoLabel = settings[2].item;
  const fondoTotal = fondoQty * fondoPrice;

  const totalSum =
    bisagraTotal + tiradorTotal + cantoTotal + melaminicoTotal + fondoTotal;

  return (
    <div className="flex flex-col mb-10">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow-sm overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("project.item")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("project.qty")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                  >
                    {t("project.unit_price")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                  >
                    {t("project.total_price")}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {/* {settings.map((a) => (
                  <Accessory key={a.id} acc={a} qty={2} />
                ))} */}
                {/* BISAGRA */}
                <tr className="group hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    {bisagraLabel}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {bisagraQty > 0 ? bisagraQty : 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                    <NumericFormat
                      value={bisagraPrice}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                    <NumericFormat
                      value={bisagraTotal}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
                {/* TIRADOR */}
                <tr className="group hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    {tiradorLabel}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {tiradorQty > 0 ? tiradorQty : 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                    <NumericFormat
                      value={tiradorPrice}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                    <NumericFormat
                      value={tiradorTotal}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
                {/* CANTO */}
                <tr className="group hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">{cantoLabel}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {cantoQty > 0 ? cantoQty : 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                    <NumericFormat
                      value={cantoPrice}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                    <NumericFormat
                      value={cantoTotal}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
                {/* MELAMINICO */}
                <tr className="group hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    {melaminicoLabel}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <NumericFormat
                      value={melaminicoQty}
                      displayType={"text"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                    <NumericFormat
                      value={melaminicoPrice}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                    <NumericFormat
                      value={melaminicoTotal}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
                {/* FONDO */}
                <tr className="group hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">{fondoLabel}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <NumericFormat
                      value={fondoQty}
                      displayType={"text"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                    <NumericFormat
                      value={fondoPrice}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                    <NumericFormat
                      value={fondoTotal}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
              </tbody>
              <tfoot className="bg-white divide-y divide-gray-200">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  ></th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  ></th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider text-right"
                  >
                    {t("project.total")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider text-right"
                  >
                    <NumericFormat
                      value={totalSum}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accessories;
