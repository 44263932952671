Object.defineProperty(exports, "__esModule", {
  value: true,
});

var _createClass = (function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _core = require("../core");

var _metaProps = require("../metaProps");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === "object" || typeof call === "function")
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError(
      "Super expression must either be null or a function, not " +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true,
    },
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var defaultProps = {
  target: "_blank",
};

var CSVDownload = (function (_React$Component) {
  _inherits(CSVDownload, _React$Component);

  function CSVDownload(props) {
    _classCallCheck(this, CSVDownload);

    var _this = _possibleConstructorReturn(
      this,
      (CSVDownload.__proto__ || Object.getPrototypeOf(CSVDownload)).call(
        this,
        props
      )
    );

    _this.state = {};
    return _this;
  }

  _createClass(CSVDownload, [
    {
      key: "buildURI",
      value: function buildURI() {
        return _core.buildURI.apply(undefined, arguments);
      },
    },
    {
      key: "componentDidMount",
      value: function componentDidMount() {
        var _props = this.props,
          data = _props.data,
          headers = _props.headers,
          separator = _props.separator,
          enclosingCharacter = _props.enclosingCharacter,
          uFEFF = _props.uFEFF,
          target = _props.target,
          specs = _props.specs,
          replace = _props.replace;

        this.state.page = window.open(
          this.buildURI(data, uFEFF, headers, separator, enclosingCharacter),
          target,
          specs,
          replace
        );
      },
    },
    {
      key: "getWindow",
      value: function getWindow() {
        return this.state.page;
      },
    },
    {
      key: "render",
      value: function render() {
        return null;
      },
    },
  ]);

  return CSVDownload;
})(_react2.default.Component);

CSVDownload.defaultProps = Object.assign(_metaProps.defaultProps, defaultProps);
CSVDownload.propTypes = _metaProps.propTypes;
exports.default = CSVDownload;
