import React from "react";

const Cut = ({ qty, width, height }) => {
  return (
    <tr className="group hover:bg-gray-50">
      <td className="px-6 py-4 whitespace-nowrap">{qty}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {width}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {height}
      </td>
    </tr>
  );
};

export default Cut;
