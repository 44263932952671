import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectsContext } from "../ProjectsContext";

import moment from "moment";
import { NumericFormat } from "react-number-format";
import ButtonEdit from "../../UI/ButtonEdit";
import { useTranslation } from "react-i18next";

const Project = ({ project }) => {
  const {
    setShowModal,
    setCurrentProject,
    setIsEditing,
    setInputProjectName,
    setInputProjectClientId,
    setInputProjectStatus,
    setInputProjectTotal,
    setInputProjectCreatedAt,
    getClientName,
  } = useContext(ProjectsContext);

  const handleEdit = (e, project) => {
    e.stopPropagation();
    setIsEditing(true);
    setCurrentProject(project);
    setInputProjectName(project.name);
    setInputProjectClientId(project.clientId);
    setInputProjectStatus(project.status);
    setInputProjectTotal(project.total);
    setInputProjectCreatedAt(project.createdAt);
    setShowModal(true);
  };

  const navigate = useNavigate();

  const viewProject = (id) => {
    navigate(`/view-project/${id}`);
  };

  const { t } = useTranslation();

  return (
    <tr
      className="group hover:bg-gray-50 cursor-pointer"
      onClick={() => {
        viewProject(project.id);
      }}
    >
      <td className="px-6 py-4 whitespace-nowrap">{project.name}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {getClientName(project.clientId)}

        {/* {clients.filter((c) => (c.id === project.clientId)[0].name)} */}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span
          className={
            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full " +
            (project.status === "inprogress"
              ? "bg-yellow-100 text-yellow-800"
              : "") +
            (project.status === "accepted"
              ? "bg-green-100 text-green-800"
              : "") +
            (project.status === "rejected" ? "bg-red-100 text-red-800" : "")
          }
        >
          {t("project." + project.status)}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <NumericFormat
          value={project.total}
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          prefix={"$"}
          decimalScale={2}
          fixedDecimalScale={true}
        />
      </td>
      <td className="flex items-center justify-between px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {moment(project.createdAt && project.createdAt.toDate()).format(
          "YYYY-MM-DD HH:mm"
        )}
        <ButtonEdit
          click={(e) => {
            handleEdit(e, project);
          }}
        />
      </td>
    </tr>
  );
};

export default Project;
