import React, { useContext } from "react";
import ProjectModal from "./Projects/Project/ProjectModal";
import Projects from "./Projects/Projects";
import { ProjectsContext } from "./Projects/ProjectsContext";
import Title from "./UI/Title";
import { useTranslation } from "react-i18next";

const Main = () => {
  const { setShowModal } = useContext(ProjectsContext);
  const { t } = useTranslation();

  return (
    <>
      <ProjectModal />

      <div className="flex justify-between">
        <Title value={t("common.projects")} />
        <button
          className="place-self-start px-2 py-1 text-sm rounded-full bg-gray-200"
          onClick={(e) => {
            setShowModal(true);
          }}
        >
          {t("common.add_new")}
        </button>
      </div>

      <Projects />
    </>
  );
};

export default Main;
