import React, {
  createContext,
  useEffect,
  useContext,
  useState,
  // useReducer,
} from "react";
import { ProjectDetailsContext } from "../Projects/Project/ProjectDetailsContext";
// import moduleTypes from "../../moduleTypes";

const initialState = {
  modulesCount: {},
};

export const CountContext = createContext(initialState);

export const CountProvider = ({ children }) => {
  // const [state, dispatch] = useReducer(ProjectDetailsReducer, initialState);
  const { modules, inputCabinetHeight, inputCabinetDepth } = useContext(
    ProjectDetailsContext
  );

  const [modulesWidth, setModulesWidth] = useState(0);
  const [cabinetSurface, setCabinetSurface] = useState(0);

  // const [countTypeA1PB, setCountTypeA1PB] = useState(0);
  const [A1PBArray, setA1PBArray] = useState([]);
  const [A2PBArray, setA2PBArray] = useState([]);
  const [A2PPArray, setA2PPArray] = useState([]);

  useEffect(() => {
    // resizeCabinet();
    // countA1PB();
    // countDoors();
    // getTypeCount("A1PB");
    if (modules !== undefined) {
      getTotalWidth();
      getSurface();
      countDoors();
    }
  }, [modules]);

  const getTypeCount = (mType) => {
    if (modules === undefined) return;
    return modules.reduce((acc, cur) => (cur.cod === mType ? ++acc : acc), 0);
  };

  const getTotalWidth = () => {
    // if (modules === undefined) return;

    setModulesWidth(modules.reduce((a, b) => a + b.width, 0));
  };

  const getSurface = () => {
    // if (modules === undefined) return;

    setCabinetSurface(modules.reduce((a, b) => a + b.width, 0));
  };

  const countDoors = () => {
    setA1PBArray(modules.filter((m) => m.cod === "A1PB"));
    setA2PBArray(modules.filter((m) => m.cod === "A2PB"));
    setA2PPArray(modules.filter((m) => m.cod === "A2PP"));
  };

  const dataCSV = [
    ["Length", "Width", "Qty"],
    [parseInt(`${inputCabinetHeight}`), parseInt(`${inputCabinetDepth}`), 2],
    [parseInt(`${modulesWidth}`), parseInt(`${inputCabinetDepth}`), 2],
    ...A1PBArray.map((m) => [
      parseInt(`${m.width}`),
      parseInt(`${inputCabinetHeight}`),
      1,
    ]),
    ...A2PBArray.map((m) => [
      parseInt(`${m.width / 2}`),
      parseInt(`${inputCabinetHeight}`),
      2,
    ]),
    ...A2PPArray.map((m) => [
      parseInt(`${m.width}`),
      parseInt(`${inputCabinetHeight / 2}`),
      2,
    ]),
  ];

  const dataTXT = [
    ["cant", "base", "height"],
    [2, parseInt(`${inputCabinetHeight}`), parseInt(`${inputCabinetDepth}`)],
    [2, parseInt(`${modulesWidth}`), parseInt(`${inputCabinetDepth}`)],
    ...A1PBArray.map((m) => [
      1,
      parseInt(`${m.width}`),
      parseInt(`${inputCabinetHeight}`),
    ]),
    ...A2PBArray.map((m) => [
      2,
      parseInt(`${m.width / 2}`),
      parseInt(`${inputCabinetHeight}`),
    ]),
    ...A2PPArray.map((m) => [
      2,
      parseInt(`${m.width}`),
      parseInt(`${inputCabinetHeight / 2}`),
    ]),
  ];

  return (
    <div>
      <CountContext.Provider
        value={{
          // modulesCount: state.modulesCount,
          A1PBArray,
          A2PBArray,
          A2PPArray,
          getTypeCount,
          modulesWidth,
          cabinetSurface,
          dataCSV,
          dataTXT,
        }}
      >
        {children}
      </CountContext.Provider>
    </div>
  );
};
