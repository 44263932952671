import React from "react";

const Modal = ({ title, children }) => {
  return (
    <div className="absolute z-50 flex items-center w-full h-full top-0 left-0 bg-gray-800 bg-opacity-90">
      <div className="p-10 w-1/3 m-auto bg-white shadow-sm">
        <h2 className="mb-5 text-2xl">{title}</h2>

        {children}
      </div>
    </div>
  );
};

export default Modal;
