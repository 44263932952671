Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports.PropsNotForwarded =
  exports.defaultProps =
  exports.propTypes =
    undefined;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var propTypes = (exports.propTypes = {
  data: (0, _propTypes.oneOfType)([_propTypes.string, _propTypes.array])
    .isRequired,
  headers: _propTypes.array,
  target: _propTypes.string,
  separator: _propTypes.string,
  filename: _propTypes.string,
  uFEFF: _propTypes.bool,
  onClick: _propTypes.func,
  asyncOnClick: _propTypes.bool,
});

var defaultProps = (exports.defaultProps = {
  separator: ",",
  filename: "generatedBy_react-csv.csv",
  uFEFF: true,
  asyncOnClick: false,
});

var PropsNotForwarded = (exports.PropsNotForwarded = ["data", "headers"]);
