export default (state, action) => {
  switch (action.type) {
    case "UPDATE_CLIENTS":
      return {
        ...state,
        clients: [...action.payload],
      };
    case "ADD_CLIENT":
      return {
        ...state,
        clients: [...state.clients, action.payload],
      };
    case "EDIT_CLIENT":
      return {
        ...state,
        clients: [...state.clients],
      };
    case "DELETE_CLIENT":
      return {
        ...state,
        clients: [...state.clients],
      };
    case "RESET_CLIENTS":
      return {
        clients: [],
      };
    default:
      return state;
  }
};
