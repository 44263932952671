import React, { useContext } from "react";
import { NumericFormat } from "react-number-format";
import ButtonEdit from "../UI/ButtonEdit";
import AccModal from "./AccModal";
import { SettingsContext } from "./SettingsContext";
import Title from "../UI/Title";
import Subtitle from "../UI/Subtitle";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const {
    settings,
    setInputItem,
    setInputQty,
    setInputPrice,
    setIsEditing,
    setShowModal,
    setCurrentAcc,
  } = useContext(SettingsContext);

  const handleEdit = (i) => {
    setIsEditing(true);
    setCurrentAcc(i.id);
    setInputItem(i.item);
    setInputQty(i.qty);
    setInputPrice(i.price);
    setShowModal(true);
  };

  const { t } = useTranslation();

  return (
    <>
      <AccModal />

      <Title value={t("common.settings")} />

      <Subtitle value={t("project.accessories")} />

      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow-sm overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("project.item")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("project.qty")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("project.price")}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {settings.map((s) => (
                    <tr key={s.id} className="group hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">{s.item}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {s.qty}
                      </td>
                      <td className="flex items-center justify-between px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <NumericFormat
                          value={s.price}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"$"}
                        />
                        <ButtonEdit
                          click={() => {
                            handleEdit(s);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
