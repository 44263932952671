import React from "react";
import "./A2PB.scss";

const A2PB = () => {
  return (
    <div className="module-A2PB">
      <div></div>
      <div></div>
    </div>
  );
};

export default A2PB;
