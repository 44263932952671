import React, { createContext, useEffect, useReducer, useState } from "react";
import firebase from "../../firebase";
import SettingsReducer from "./SettingsReducer";

const initialState = {
  settings: [],
};

export const SettingsContext = createContext(initialState);

export const SettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SettingsReducer, initialState);

  const [isEditing, setIsEditing] = useState(false);
  const [inputItem, setInputItem] = useState("");
  const [inputQty, setInputQty] = useState("");
  const [inputPrice, setInputPrice] = useState();
  const [currentAcc, setCurrentAcc] = useState();

  const [showModal, setShowModal] = useState(false);

  const db = firebase.firestore();
  const settingsRef = db.collection("accessories");

  useEffect(() => {
    const doc = settingsRef.orderBy("item");

    var unsubscribe = doc.onSnapshot(
      (snapshot) => {
        const settingsData = [...state.settings];

        snapshot.forEach((doc) => {
          settingsData.push({ ...doc.data(), id: doc.id });
        });

        dispatch({
          type: "UPDATE_SETTINGS",
          payload: settingsData,
        });
      },
      (err) => {
        console.error(`Encountered error: ${err}`);
      }
    );

    return unsubscribe;
  }, [db]);

  const editPrice = (id, updatedPrice) => {
    const updPrice = settingsRef.doc(id).set(updatedPrice);

    dispatch({
      type: "EDIT_SETTING",
      payload: updPrice,
    });
  };

  return (
    <div>
      <SettingsContext.Provider
        value={{
          settings: state.settings,
          inputItem,
          setInputItem,
          inputQty,
          setInputQty,
          inputPrice,
          setInputPrice,
          currentAcc,
          setCurrentAcc,
          editPrice,
          isEditing,
          setIsEditing,
          showModal,
          setShowModal,
        }}
      >
        {children}
      </SettingsContext.Provider>
    </div>
  );
};
