import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "../../hooks/useClickOutside";
import A1PB from "../../images/module-icons/A1PB.svg";
import A2PB from "../../images/module-icons/A2PB.svg";
import A2PP from "../../images/module-icons/A2PP.svg";

const ButtonAddModule = ({ setModuleType, handleAddModule }) => {
  const [isOn, setIsOn] = useState(false);

  const { t } = useTranslation();

  const handleSelectModule = (module) => {
    setModuleType(module);
    handleAddModule(module);
  };

  const moduleOptions = [
    { cod: "A1PB", img: A1PB, name: "1 Puerta Batiente" },
    { cod: "A2PB", img: A2PB, name: "2 Puertas Batientes" },
    { cod: "A2PP", img: A2PP, name: "2 Puertas Proyectantes" },
  ];

  // detect when user clicks outside menu and closes it
  const menuRef = useClickOutside((e) => {
    setIsOn(false);
  });

  return (
    <div className="relative" ref={menuRef}>
      <div
        className="inline-flex items-center uppercase text-xs font-medium cursor-pointer p-1 pr-2 rounded-full bg-green-400 hover:bg-green-500 text-white"
        id="options-menu"
        aria-haspopup={isOn}
        aria-expanded={isOn}
        onClick={() => setIsOn(!isOn)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-5 h-5"
        >
          <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
        </svg>
        {t("project.module")}
      </div>

      <div
        className="origin-top-right absolute left-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
        onClick={() => setIsOn(!isOn)}
        hidden={!isOn}
      >
        <div
          className="py-1"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {moduleOptions.map((m) => (
            <div
              key={m.cod}
              className="flex items-center pr-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
              role="menuitem"
              onClick={() => handleSelectModule(m.cod)}
            >
              <img className="block w-6 h-6 mx-3" src={m.img} alt={m.name} />
              {m.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ButtonAddModule;
