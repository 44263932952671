import React, { createContext, useReducer, useState, useEffect } from "react";
import firebase from "../../firebase";
import ClientsReducer from "./ClientsReducer";

const initialState = {
  clients: [],
};

export const ClientsContext = createContext(initialState);

export const ClientsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ClientsReducer, initialState);

  const [isEditing, setIsEditing] = useState(false);
  const [inputClientName, setInputClientName] = useState("");
  const [inputClientRate, setInputClientRate] = useState("");
  const [inputClientColor, setInputClientColor] = useState("");
  const [currentClient, setCurrentClient] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const db = firebase.firestore();
  const clientsRef = db.collection("clients");

  useEffect(() => {
    const doc = clientsRef.orderBy("name");

    var unsubscribe = doc.onSnapshot(
      (snapshot) => {
        const clientsData = [...state.clients];

        snapshot.forEach((doc) => {
          clientsData.push({ ...doc.data(), id: doc.id });
        });

        dispatch({
          type: "UPDATE_CLIENTS",
          payload: clientsData,
        });
      },
      (err) => {
        console.error(`Encountered error: ${err}`);
      }
    );

    return unsubscribe;
  }, [db]);

  const addClient = (client) => {
    const newClient = clientsRef.add(client);

    dispatch({
      type: "ADD_CLIENT",
      payload: newClient,
    });
  };

  const editClient = (id, updatedClient) => {
    const updClient = clientsRef.doc(id).set(updatedClient);

    dispatch({
      type: "EDIT_CLIENT",
      payload: updClient,
    });
  };

  const deleteClient = (id) => {
    const delClient = clientsRef.doc(id).delete();

    dispatch({
      type: "DELETE_CLIENT",
      payload: delClient,
    });
  };

  const resetClients = () => {
    dispatch({
      type: "RESET_CLIENTS",
    });
  };

  return (
    <div>
      <ClientsContext.Provider
        value={{
          clients: state.clients,
          inputClientName,
          setInputClientName,
          inputClientRate,
          setInputClientRate,
          inputClientColor,
          setInputClientColor,
          isEditing,
          setIsEditing,
          addClient,
          editClient,
          deleteClient,
          resetClients,
          currentClient,
          setCurrentClient,
          showModal,
          setShowModal,
        }}
      >
        {children}
      </ClientsContext.Provider>
    </div>
  );
};
