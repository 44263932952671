import React from "react";
import "./A1PB.scss";

const A1PB = () => {
  return (
    <div className="module-A1PB">
      <div></div>
    </div>
  );
};

export default A1PB;
