// @ts-nocheck
import React, { useState, useEffect, useContext } from "react";
import * as v from "./variables";
import Module from "./Module";
import moduleTypes from "../../moduleTypes";
import ButtonAddModule from "../UI/ButtonAddModule";
import ButtonFit from "../UI/ButtonFit";
import { ProjectDetailsContext } from "../Projects/Project/ProjectDetailsContext";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import "./styles.scss";

const Cabinet = () => {
  const {
    inputCabinetWidth,
    setInputCabinetWidth,
    inputCabinetHeight,
    setInputCabinetHeight,
    inputCabinetDepth,
    setInputCabinetDepth,
    modules,
    setModuleType,
    addModule,
    reorderModules,
    setIsEditing,
  } = useContext(ProjectDetailsContext);

  const [isMounted, setIsMounted] = useState(false);

  const getModulesSum = () => {
    // Sum the width from all modules
    const modulesWidth =
      modules &&
      modules.reduce((accumulator, object) => {
        return accumulator + object.width;
      }, 0);

    if (modulesWidth > 0) {
      return modulesWidth;
    } else {
      return 0;
    }
  };

  const handleAddModule = (module) => {
    const defaultModuleWidth = moduleTypes[module].defaultW;
    addModule({ cod: module, width: defaultModuleWidth });
    setIsEditing(true);

    setInputCabinetWidth(getModulesSum() + defaultModuleWidth);
  };

  const handleFitModules = () => {
    const modulesW = getModulesSum();

    // Re-adjust cabinet width to match modules width sum
    setInputCabinetWidth(modulesW);
    setIsEditing(true);
  };

  const getCabinetSize = () => {
    if (getModulesSum() > inputCabinetWidth) {
      return "smaller";
    } else if (getModulesSum() === inputCabinetWidth) {
      return "equal";
    } else {
      return "bigger";
    }
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const inputs = document.querySelectorAll(".module input");
    inputs.forEach((el) => {
      el.blur();
    });

    reorderModules(modules, result.source.index, result.destination.index);
    setIsEditing(true);
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const { t } = useTranslation();

  return (
    <div className="cabinet-container">
      <div className="cabinet-container__header flex">
        <label className="flex items-center text-xs font-medium uppercase mr-6">
          {t("project.width")}
          <input
            className={
              "text-sm px-2 py-1 mr-1 border rounded-sm ml-2 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500" +
              (getCabinetSize() === "smaller"
                ? " text-red-500 font-medium border-red-500 focus:border-red-500 focus:ring-red-500"
                : " border-gray-300")
            }
            type="number"
            min={v.MIN_WIDTH_ALLOWED}
            step="10"
            value={inputCabinetWidth}
            onChange={(e) => {
              setInputCabinetWidth(parseInt(e.target.value));
              setIsEditing(true);
            }}
            onBlur={(e) =>
              parseInt(e.target.value) >= v.MIN_WIDTH_ALLOWED
                ? setInputCabinetWidth(parseInt(e.target.value))
                : setInputCabinetWidth(v.MIN_WIDTH_ALLOWED)
            }
          />
          <ButtonFit
            disabled={getCabinetSize() === "equal"}
            click={() => {
              handleFitModules(getModulesSum);
            }}
          />
        </label>
        <label className="inline-block text-xs font-medium uppercase mr-6">
          {t("project.height")}
          <input
            className="text-sm px-2 py-1 border border-gray-300 rounded-sm ml-2 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            type="number"
            min="400"
            step="5"
            value={inputCabinetHeight}
            onChange={(e) => {
              setInputCabinetHeight(parseInt(e.target.value));
              setIsEditing(true);
            }}
            onBlur={(e) =>
              parseInt(e.target.value) >= v.MIN_HEIGHT_ALLOWED
                ? setInputCabinetHeight(parseInt(e.target.value))
                : setInputCabinetHeight(v.MIN_HEIGHT_ALLOWED)
            }
          />
        </label>
        <label className="inline-block text-xs font-medium uppercase mr-6">
          {t("project.depth")}
          <input
            className="text-sm px-2 py-1 border border-gray-300 rounded-sm ml-2 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            type="number"
            min="200"
            step="5"
            value={inputCabinetDepth}
            onChange={(e) => {
              setInputCabinetDepth(parseInt(e.target.value));
              setIsEditing(true);
            }}
            onBlur={(e) =>
              parseInt(e.target.value) >= v.DEFAULT_DEPTH
                ? setInputCabinetDepth(parseInt(e.target.value))
                : setInputCabinetDepth(v.DEFAULT_DEPTH)
            }
          />
        </label>
        <ButtonAddModule
          setModuleType={setModuleType}
          handleAddModule={handleAddModule}
        />
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        {isMounted ? (
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={
                  "cabinet-container__top container mb-8" +
                  (snapshot.isDragging ? " is-dragging" : "") +
                  (getCabinetSize() === "smaller" ? " is-smaller" : "")
                }
                // style={getListStyle(snapshot.isDraggingOver)}
                style={{
                  width: (inputCabinetWidth / 10) * 2 + "px",
                  height: (inputCabinetHeight / 10) * 2 + "px",
                  minWidth: (v.MIN_WIDTH_ALLOWED / 10) * 2 + "px",
                  minHeight: (v.MIN_HEIGHT_ALLOWED / 10) * 2 + "px",
                }}
              >
                {modules &&
                  modules.map((m, index) => (
                    <Draggable
                      key={index}
                      draggableId={index.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={
                            "module group" +
                            (snapshot.isDragging ? " is-dragging" : "")
                          }
                          style={provided.draggableProps.style}
                          // style={getItemStyle(
                          //   snapshot.isDragging,
                          //   provided.draggableProps.style
                          // )}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Module
                            key={index}
                            index={index}
                            cod={m.cod}
                            width={m.width}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ) : null}
      </DragDropContext>
    </div>
  );
};

export default Cabinet;
