const moduleTypes = {
  A1PB: {
    puertas: 1,
    bisagra: 2,
    tirador: 1,
    cantoW: 2,
    cantoH: 2,
    defaultW: 400,
  },
  A2PB: {
    puertas: 2,
    bisagra: 4,
    tirador: 2,
    cantoW: 4,
    cantoH: 2,
    defaultW: 800,
  },
  A2PP: {
    puertas: 2,
    bisagra: 4,
    tirador: 2,
    cantoW: 2,
    cantoH: 4,
    defaultW: 600,
  },
};

export default moduleTypes;
