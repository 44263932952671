import React, { useContext } from "react";
import Client from "./Client";
import ClientModal from "./ClientModal";
import Title from "../UI/Title";
import { ClientsContext } from "./ClientsContext";
import { useTranslation } from "react-i18next";

const Clients = () => {
  const { clients, setShowModal } = useContext(ClientsContext);
  const { t } = useTranslation();

  return (
    <>
      <ClientModal />

      <div className="flex justify-between">
        <Title value={t("common.clients")} />
        <button
          className="place-self-start px-2 py-1 text-sm rounded-full bg-gray-200"
          onClick={(e) => {
            setShowModal(true);
          }}
        >
          {t("common.add_new")}
        </button>
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {clients.map((client) => (
          <Client key={client.id} client={client} />
        ))}
      </div>
    </>
  );
};

export default Clients;
