import React, { useContext } from "react";
import { CountContext } from "../../Cabinet/CountContext";
import Cut from "./Cut";
import { ProjectDetailsContext } from "./ProjectDetailsContext";
import { useTranslation } from "react-i18next";

const Cuts = () => {
  const { modules, inputCabinetHeight, inputCabinetDepth } = useContext(
    ProjectDetailsContext
  );
  const { modulesWidth, A1PBArray, A2PBArray, A2PPArray } =
    useContext(CountContext);

  const { t } = useTranslation();

  // console.log(modules.length);

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow-sm overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("project.qty")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("project.base")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("project.height")}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {/* SIDES */}
                <tr
                  className="group hover:bg-gray-50"
                  hidden={modules === undefined}
                >
                  <td className="px-6 py-4 whitespace-nowrap">2</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {inputCabinetHeight}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {inputCabinetDepth}
                  </td>
                </tr>
                {/* TOP BOTTOM */}
                <tr
                  className="group hover:bg-gray-50"
                  hidden={modules === undefined}
                >
                  <td className="px-6 py-4 whitespace-nowrap">2</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {modulesWidth}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {inputCabinetDepth}
                  </td>
                </tr>
                {/* FRONTS */}
                {A1PBArray.map((a, index) => (
                  <Cut
                    key={index}
                    qty={1}
                    width={a.width}
                    height={inputCabinetHeight}
                  />
                ))}
                {A2PBArray.map((a, index) => (
                  <Cut
                    key={index}
                    qty={2}
                    width={a.width / 2}
                    height={inputCabinetHeight}
                  />
                ))}
                {A2PPArray.map((a, index) => (
                  <Cut
                    key={index}
                    qty={2}
                    width={a.width}
                    height={inputCabinetHeight / 2}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cuts;
