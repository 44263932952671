export default (state, action) => {
  switch (action.type) {
    case "LOAD_PROJECT_DETAILS":
      return {
        ...state,
        projectData: { ...action.payload },
      };
    case "UPDATE_PROJECT_DETAILS":
      return {
        ...state,
        projectData: { ...state.payload },
      };
    default:
      return state;
  }
};
