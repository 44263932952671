import React, { useContext } from "react";
import config from "../firebase";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../Auth";
import { ClientsContext } from "./Clients/ClientsContext";
import logo from "../images/veta-logo.svg";
import { useTranslation } from "react-i18next";

const Nav = () => {
  const { currentUser } = useContext(AuthContext);
  const { resetClients } = useContext(ClientsContext);
  const { t, i18n } = useTranslation();

  function logout() {
    resetClients();
    config.auth().signOut();
  }

  const toggleLanguage = () => {
    if (i18n.language === "es") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("es");
    }
  };

  return (
    <>
      {currentUser && (
        <header className="flex justify-between items-center h-20 px-6 bg-gray-600 text-white">
          <NavLink to="/">
            <img className="block h-12 px-2 py-1" src={logo} alt="Veta Logo" />
          </NavLink>
          <ul className="flex justify-between justify-items-center">
            <li className="flex items-center m-1">
              <div
                className="px-5 py-3 block text-sm rounded-md uppercase cursor-pointer hover:bg-gray-800"
                onClick={() => toggleLanguage()}
              >
                {i18n.language}
              </div>
            </li>
            <li className="flex items-center m-1">
              <NavLink
                className={(navData) =>
                  navData.isActive
                    ? "px-5 py-3 block text-sm rounded-md hover:bg-gray-800 bg-gray-800"
                    : "px-5 py-3 block text-sm rounded-md hover:bg-gray-800"
                }
                to="/"
              >
                {t("common.projects")}
              </NavLink>
            </li>
            <li className="flex items-center m-1">
              <NavLink
                className={(navData) =>
                  navData.isActive
                    ? "px-5 py-3 block text-sm rounded-md hover:bg-gray-800 bg-gray-800"
                    : "px-5 py-3 block text-sm rounded-md hover:bg-gray-800"
                }
                to="/clients"
              >
                {t("common.clients")}
              </NavLink>
            </li>
            <li className="flex items-center m-1">
              <NavLink
                className={(navData) =>
                  navData.isActive
                    ? "px-5 py-3 block text-sm rounded-md hover:bg-gray-800 bg-gray-800"
                    : "px-5 py-3 block text-sm rounded-md hover:bg-gray-800"
                }
                to="/settings"
              >
                {t("common.settings")}
              </NavLink>
            </li>
            <li className="flex items-center m-1">
              <span
                className="px-5 py-3 block text-sm rounded-md hover:bg-gray-800 cursor-pointer"
                onClick={logout}
              >
                {t("common.logout")}
              </span>
            </li>
          </ul>
        </header>
      )}
    </>
  );
};

export default Nav;
