import React, { useContext, useState } from "react";
import { CSVLink } from "../../libraries/react-csv";
import { CountContext } from "../Cabinet/CountContext";
import { ProjectDetailsContext } from "../Projects/Project/ProjectDetailsContext";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "../../hooks/useClickOutside";

const DropDownExport = () => {
  const [isOn, setIsOn] = useState(false);

  const { projectData } = useContext(ProjectDetailsContext);
  const { dataCSV, dataTXT } = useContext(CountContext);

  const separator = "\t";
  const encChar = "";
  const filename = `veta-${projectData.name}`;

  const { t } = useTranslation();

  // detect when user clicks outside menu and closes it
  const menuRef = useClickOutside((e) => {
    setIsOn(false);
  });

  return (
    <div className="relative inline-block text-left" ref={menuRef}>
      <div>
        <button
          type="button"
          className="flex px-2 py-1 text-sm rounded-full bg-gray-200"
          id="options-menu"
          aria-haspopup={isOn}
          aria-expanded={isOn}
          onClick={() => setIsOn(!isOn)}
        >
          {t("project.export")}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      <div
        className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
        onClick={() => setIsOn(!isOn)}
        hidden={!isOn}
      >
        <div
          className="py-1"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <CSVLink
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            data={dataCSV}
            filename={`${filename}.csv`}
          >
            {t("project.export_csv")}
          </CSVLink>
          <CSVLink
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            data={dataTXT}
            separator={separator}
            enclosingCharacter={encChar}
            filename={`${filename}.txt`}
          >
            {t("project.export_txt")}
          </CSVLink>
        </div>
      </div>
    </div>
  );
};

export default DropDownExport;
