import React, { useContext } from "react";
import { ClientsContext } from "./ClientsContext";
import Modal from "../UI/Modal";
import { useTranslation } from "react-i18next";

const ClientModal = () => {
  const {
    inputClientName,
    setInputClientName,
    inputClientRate,
    setInputClientRate,
    inputClientColor,
    setInputClientColor,
    isEditing,
    setIsEditing,
    addClient,
    editClient,
    deleteClient,
    currentClient,
    showModal,
    setShowModal,
  } = useContext(ClientsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputClientName === "" || inputClientColor === "") return;

    const clientDetails = {
      name: inputClientName,
      rate: 1,
      color: inputClientColor,
    };

    isEditing
      ? editClient(currentClient.id, clientDetails)
      : addClient(clientDetails);
    setShowModal(false);
    handleClear();
  };

  const handleDelete = () => {
    deleteClient(currentClient.id);
    setIsEditing(false);
    handleClear();
    closeModal();
  };

  const handleClear = () => {
    setInputClientName("");
    setInputClientRate("");
    setInputClientColor("");
  };

  const closeModal = () => {
    handleClear();
    setIsEditing(false);
    setShowModal(false);
  };

  const { t } = useTranslation();

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      title={isEditing ? t("project.edit_client") : t("project.new_client")}
    >
      <form onSubmit={handleSubmit}>
        <input
          className="block px-1 h-10 mb-2 w-full rounded-sm border-gray-300 border"
          name="clientName"
          type="input"
          value={inputClientName}
          onChange={(e) => setInputClientName(e.target.value)}
          placeholder={t("project.client_name")}
        />

        <input
          className="px-1 h-10 mb-2 w-full rounded-sm border-gray-300 border"
          name="clientRate"
          type="number"
          value={inputClientRate}
          onChange={(e) => setInputClientRate(e.target.value)}
          placeholder={t("project.client_rate")}
        />

        <select
          className={
            "block px-1 h-10 mb-2 w-full rounded-sm border-gray-300 border " +
            (inputClientColor === "" ? "text-gray-400" : "")
          }
          onChange={(e) => setInputClientColor(e.target.value)}
          value={inputClientColor}
        >
          <option className="opacity-50" value="">
            {t("common.select_color")}
          </option>
          <option value="red">{t("common.red")}</option>
          <option value="yellow">{t("common.yellow")}</option>
          <option value="green">{t("common.green")}</option>
          <option value="blue">{t("common.blue")}</option>
          <option value="indigo">{t("common.indigo")}</option>
          <option value="purple">{t("common.purple")}</option>
          <option value="pink">{t("common.pink")}</option>
        </select>

        <div className="flex justify-end mt-5">
          {isEditing && (
            <button
              className="px-5 py-3 mr-auto inline-block text-md rounded-md text-white bg-red-400 hover:bg-red-300"
              type="button"
              onClick={() => {
                handleDelete();
              }}
            >
              {t("common.delete")}
            </button>
          )}

          <button
            className="px-5 py-3 inline-block text-md rounded-md text-white bg-gray-400 hover:bg-gray-300"
            type="button"
            onClick={(e) => {
              closeModal();
            }}
          >
            {t("common.cancel")}
          </button>
          <button
            className="px-5 py-3 ml-2 inline-block text-md rounded-md text-white bg-green-400 hover:bg-green-300"
            type="submit"
          >
            {isEditing ? t("common.save") : t("common.add")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ClientModal;
