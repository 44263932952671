import React from "react";

const ButtonEdit = ({ click }) => {
  return (
    <div
      onClick={click}
      className="inline-flex cursor-pointer p-2 ml-2 opacity-0 rounded-full hover:bg-gray-200 group-hover:opacity-50 hover:opacity-80 text-gray-400 hover:text-gray-700"
    >
      <svg
        className="h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
      </svg>
    </div>
  );
};

export default ButtonEdit;
