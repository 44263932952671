import React, { useContext } from "react";
import ButtonEdit from "../UI/ButtonEdit";
import { ClientsContext } from "./ClientsContext";

const Client = ({ client }) => {
  const {
    setShowModal,
    setCurrentClient,
    setIsEditing,
    setInputClientName,
    setInputClientColor,
  } = useContext(ClientsContext);

  const handleEdit = (client) => {
    setIsEditing(true);
    setInputClientName(client.name);
    setInputClientColor(client.color);
    setCurrentClient(client);
    setShowModal(true);
  };

  const getClientInitials = (n) => {
    // console.log(n);
    if (n === undefined) return;

    const initials = n
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase()
      .slice(0, 2);

    return initials;
  };

  return (
    <div className="group flex items-center p-5 shadow-sm border-b border-gray-50 bg-white rounded-lg">
      <div
        className={`h-10 w-10 mr-4 rounded-full bg-gray-300 bg-${client.color}-400 text-white font-bold items-center flex justify-center`}
      >
        {getClientInitials(client.name)}
      </div>
      <h2 className="flex-1 text-lg">{client.name}</h2>
      <ButtonEdit
        click={() => {
          handleEdit(client);
        }}
      />
    </div>
  );
};

export default Client;
