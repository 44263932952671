import React, {
  createContext,
  useReducer,
  useState,
  useEffect,
  useContext,
} from "react";
import firebase from "../../firebase";
import { ClientsContext } from "../Clients/ClientsContext";
import ProjectsReducer from "./ProjectsReducer";

const initialState = {
  projects: [],
};

export const ProjectsContext = createContext(initialState);

export const ProjectsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProjectsReducer, initialState);

  const [isEditing, setIsEditing] = useState(false);
  const [inputProjectName, setInputProjectName] = useState("");
  const [inputProjectClientId, setInputProjectClientId] = useState("");
  const [inputProjectStatus, setInputProjectStatus] = useState("");
  const [inputProjectTotal, setInputProjectTotal] = useState("0");
  const [inputProjectCreatedAt, setInputProjectCreatedAt] = useState("");
  const [currentProject, setCurrentProject] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { clients } = useContext(ClientsContext);

  const db = firebase.firestore();
  const projectsRef = db.collection("projects");

  useEffect(() => {
    const doc = projectsRef.orderBy("createdAt", "desc");

    var unsubscribe = doc.onSnapshot(
      (snapshot) => {
        const projectsData = [...state.projects];

        snapshot.forEach((doc) => {
          projectsData.push({ ...doc.data(), id: doc.id });
        });

        dispatch({
          type: "UPDATE_PROJECTS",
          payload: projectsData,
        });
      },
      (err) => {
        console.error(`Encountered error: ${err}`);
      }
    );

    return unsubscribe;
  }, [db]);

  const addProject = (project) => {
    const newProject = projectsRef.add(project);

    dispatch({
      type: "ADD_PROJECT",
      payload: newProject,
    });
  };

  const editProject = (id, updatedProject) => {
    const updProject = projectsRef.doc(id).update(updatedProject);

    dispatch({
      type: "EDIT_PROJECT",
      payload: updProject,
    });
  };

  const deleteProject = (id) => {
    const delProject = projectsRef.doc(id).delete();

    dispatch({
      type: "DELETE_PROJECT",
      payload: delProject,
    });
  };

  const resetProjects = () => {
    dispatch({
      type: "RESET_PROJECTS",
    });
  };

  const getClientName = (clientID) => {
    if (clientID === undefined) return;

    const clientIndex = Object.keys(clients).filter(
      (c) => clients[c].id === clientID
    );

    if (clientIndex.length === 0) return "Deleted User";

    const cName = Object.keys(clients).map((s) => clients[s])[clientIndex].name;

    return cName;
  };

  return (
    <div>
      <ProjectsContext.Provider
        value={{
          projects: state.projects,
          inputProjectName,
          setInputProjectName,
          inputProjectClientId,
          setInputProjectClientId,
          inputProjectStatus,
          setInputProjectStatus,
          inputProjectTotal,
          setInputProjectTotal,
          inputProjectCreatedAt,
          setInputProjectCreatedAt,
          isEditing,
          setIsEditing,
          addProject,
          editProject,
          deleteProject,
          resetProjects,
          currentProject,
          setCurrentProject,
          showModal,
          setShowModal,
          getClientName,
        }}
      >
        {children}
      </ProjectsContext.Provider>
    </div>
  );
};
