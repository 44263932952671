import React, { createContext, useEffect, useReducer, useState } from "react";
import {
  DEFAULT_WIDTH,
  DEFAULT_HEIGHT,
  DEFAULT_DEPTH,
} from "../../Cabinet/variables";
import firebase from "../../../firebase";
import ProjectDetailsReducer from "./ProjectDetailsReducer";

const initialState = {
  projectData: {},
};

export const ProjectDetailsContext = createContext(initialState);

export const ProjectDetailsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProjectDetailsReducer, initialState);

  const [isEditing, setIsEditing] = useState(false);
  const [currentProject, setCurrentProject] = useState();

  const [moduleType, setModuleType] = useState("A1PB");

  const [inputCabinetWidth, setInputCabinetWidth] = useState(DEFAULT_WIDTH);
  const [inputCabinetHeight, setInputCabinetHeight] = useState(DEFAULT_HEIGHT);
  const [inputCabinetDepth, setInputCabinetDepth] = useState(DEFAULT_DEPTH);

  const [modules, setModules] = useState([]);

  const db = firebase.firestore();
  const projectDataRef = db.collection("projects");

  const loadProject = (proj) => {
    const unsubscribe = projectDataRef.doc(proj).onSnapshot(
      (doc) => {
        doc.exists && getData(doc.data());
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    loadProject(currentProject);

    // return unsubscribe;
  }, [currentProject, db]);

  const getData = (data) => {
    dispatch({
      type: "LOAD_PROJECT_DETAILS",
      payload: data,
    });

    setInputCabinetWidth(data.cabinet.width);
    setInputCabinetHeight(data.cabinet.height);
    setInputCabinetDepth(data.cabinet.depth);
    setModules(data.modules);
  };

  const updateProject = (id, updatedProject) => {
    const updProject = projectDataRef.doc(id).update(updatedProject);

    dispatch({
      type: "UPDATE_PROJECT_DETAILS",
      payload: updProject,
    });
  };

  const addModule = (mod) => {
    modules ? setModules([...modules, mod]) : setModules([mod]);
    // !modules && setModules([...modules, mod]);
  };

  const editModule = (mod, w) => {
    const updMods = [...modules];
    updMods[mod] = {
      cod: modules[mod].cod,
      width: w,
    };
    setModules(updMods);
  };

  // const resizeCabinet = () => {
  //   setModulesWidth(modules.reduce((a, b) => a + b.width, 0));

  //   if (modulesWidth >= inputCabinetWidth) {
  //     setInputCabinetWidth(modulesWidth);
  //   }
  // };

  const removeModule = (i) => {
    const updMods = modules
      .slice(0, i)
      .concat(modules.slice(i + 1, modules.length));

    setModules(updMods);
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const reorderModules = (m, s, d) => {
    const ordMods = reorder(m, s, d);

    setModules(ordMods);
  };

  return (
    <div>
      <ProjectDetailsContext.Provider
        value={{
          projectData: state.projectData,
          currentProject,
          setCurrentProject,
          loadProject,
          updateProject,
          isEditing,
          setIsEditing,
          inputCabinetWidth,
          setInputCabinetWidth,
          inputCabinetHeight,
          setInputCabinetHeight,
          inputCabinetDepth,
          setInputCabinetDepth,
          moduleType,
          setModuleType,
          modules,
          setModules,
          addModule,
          editModule,
          reorderModules,
          removeModule,
        }}
      >
        {children}
      </ProjectDetailsContext.Provider>
    </div>
  );
};
